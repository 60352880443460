@import 'styles/variables';

.addNewUser {
  .addNewContainer {
    display: flex;
    justify-content: space-between;

    .addNewForm {
      margin-right: 10px;
      width: calc(100% - 382px);

      .basicInfo {
        background-color: #fff;
        box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
        border-radius: 8px;
        .title {
          font-weight: 600;
          font-size: 18px;
          color: #27272a;
          margin-bottom: 20px;
        }

        .department {
          :global {
            .ant-row {
              padding-top: 12px;
              padding-bottom: 20px;
            }
          }
        }
      }

      .detailInfo {
        position: relative;
        background-color: #fff;
        box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
        border-radius: 8px;
        margin-top: 20px;

        .titleDetail {
          font-weight: 600;
          font-size: 18px;
          color: #27272a;
        }

        .importUserList {
          display: flex;
          justify-content: space-between;
          background-color: #f6fbff;
          margin: 0px 0 20px 0;
          padding: 14px;
          border-radius: 4px;

          .importContent {
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-weight: 400;
              font-size: 14px;
              color: #4b4b4b;
              margin-left: 10px;
              font-family: 'Poppins Regular';
            }
          }

          .importText {
            color: $blue-60;
            cursor: pointer;
            user-select: none;
            font-size: $font-size-base;
          }
        }
      }

      .bntBox {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 27px;

        :global {
          .ant-btn {
            width: 128px;
            height: 40px;
          }

          .var-primary-button {
            span {
              margin-left: 5px;
            }
          }

          .mb-0 {
            margin-bottom: 0;
          }
        }
      }
    }

    .userGuide {
      margin-left: 10px;
      width: 382px;
      border-radius: 8px;
      :global {
        .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-heade {
          padding: 16px;
        }
      }
      .note1 {
        border-top: 1px dashed $blue-gray-30;
        padding-top: 20px;
      }
      .userGuideContent {
        box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
        border-radius: 8px;

        :global {
          .note {
            font-family: $font-semibold;
            font-size: $font-size-base;
            color: $text-bw2;
          }
          .text {
            font-family: $font-regular;
            font-size: $font-size-base;
            color: $text-bw3;
            margin-bottom: 0;
          }
          .ant-divider-horizontal {
            margin: 12px 0;
          }
        }

        .userGuideTitle {
          font-weight: 600;
          font-size: 16px;
          color: #4b4b4b;
        }

        .note {
          .noteTitle {
            font-weight: 600;
            font-size: 14px;
            color: #4b4b4b;
          }
          .noteContent {
            font-weight: 400;
            font-size: 13px;
            color: #6f7282;
          }
        }
      }
    }
  }
}

.basicInfo,
.detailInfo {
  :global {
    .ant-collapse-header-text {
      font-size: $font-size-medium;
      font-family: $font-semibold;
      color: $text-bw1;
    }
    .ant-collapse-expand-icon {
      position: absolute !important;
      right: 10px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 20px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 20px;
    }
    .ant-collapse {
      background: $white;
      border: unset;
      border-radius: $border-radius-tiny;
    }
    .ant-collapse-item:last-child > .ant-collapse-content {
      border-radius: 0 0 8px 8px;
    }
    .ant-collapse-content {
      border-top: unset;
    }
    .ant-collapse > .ant-collapse-item {
      border-bottom: unset;
    }
  }
}
.userGuideContent {
  :global {
    .ant-collapse-header-text {
      font-size: $font-size-medium;
      font-family: $font-semibold;
      color: $text-bw1;
    }
    .ant-collapse-expand-icon {
      position: absolute !important;
      right: 10px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 16px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 16px 16px 16px;
    }
    .ant-collapse {
      background: $white;
      border: unset;
      border-radius: $border-radius-tiny;
    }
    .ant-collapse-item:last-child > .ant-collapse-content {
      border-radius: 0 0 8px 8px;
    }
    .ant-collapse-content {
      border-top: unset;
    }
    .ant-collapse > .ant-collapse-item {
      border-bottom: unset;
    }
  }
}
.internalUserForm {
  margin-bottom: 20px !important;
  width: 100%;
  margin-top: 4px;
  :global {
    .ant-form-item-control-input {
      input {
        height: unset;
        padding: 8px 8px 8px 16px;
      }
    }
    .ant-picker {
      padding: 0;
      .ant-picker-suffix {
        margin-right: 8px;
      }
    }
    .ant-form-item-explain-error {
      font-size: 12px;
      font-style: normal;
      float: left;
    }
  }
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.05);
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}
