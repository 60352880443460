@import 'styles/variables';

.box {
  :global {
    .group-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 28px;
      padding-bottom: 20px;
      .var-default-button {
        margin-right: 20px;
      }
      .var-primary-button {
        span {
          margin-left: 5px;
        }
      }
      .ant-btn {
        height: 40px;
        width: 128px;
      }
    }

    .ant-form-item-explain {
      display: flex;
      flex-direction: column;

      .ant-form-item-explain-error {
        font-size: $font-size-tiny;
        font-family: $font-regular;
        color: $red-60;
        padding-top: 4px;
        float: left;
        font-style: normal;
      }
    }

    .pb-1 {
      padding-bottom: 4px;
    }

    .mb-0 {
      margin-bottom: 0;
    }
  }
}
.addNewUser,
.externalUserGuide,
.companyUserDetail {
  :global {
    .ant-collapse-header-text {
      font-size: $font-size-medium;
      font-family: $font-semibold;
      color: $text-bw1;
    }
    .ant-collapse-expand-icon {
      position: absolute !important;
      right: 10px;
    }
    .ant-collapse {
      background: $white;
      border: unset;
      border-radius: $border-radius-tiny;
      padding: 8px;
      box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
    }
    .ant-collapse-item:last-child > .ant-collapse-content {
      border-radius: 0 0 8px 8px;
    }
    .ant-collapse-content {
      border-top: unset;
    }
    .ant-collapse > .ant-collapse-item {
      border-bottom: unset;
    }
  }
}

.addNewUser,
.companyUserDetail {
  :global {
    .select-company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      background-color: $blue-3;
      border-radius: $border-radius-extra-micro;
      padding: 0 12px;
      margin-bottom: 20px;
      .text {
        margin-left: 8px;
        color: $text-bw2;
        font-size: $font-size-base;
        font-family: $font-regular;
        margin-right: 15px;
      }
      .create-new-company {
        color: $blue-60;
        cursor: pointer;
        user-select: none;
        font-size: $font-size-base;
      }
      .flex {
        display: flex;
      }
      .items-center {
        align-items: center;
      }
      .text-label {
        margin-bottom: 4px;
      }
    }
  }
}

.externalUserGuide {
  :global {
    .note {
      font-family: $font-semibold;
      font-size: $font-size-base;
      color: $text-bw2;
    }
    .text {
      font-family: $font-regular;
      font-size: $font-size-base;
      color: $text-bw3;
    }
    .ant-divider-horizontal {
      margin: 12px 0;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 16px 16px 16px;
    }
  }
}
.note1 {
  padding-top: 16px;
  border-top: 1px dashed $blue-gray-30;
}

.switchInputDisabled {
  span {
    color: rgba(0, 0, 0, 0.15);
  }
}

.modalConfirm {
  :global {
    .ant-modal-content {
      border-radius: $border-radius-tiny;

      button {
        border-radius: 6px;
      }

      button[disabled] {
        background-color: #f5f5f5 !important;
      }
    }
  }
}