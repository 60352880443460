@import 'styles/variables';

.switch {
  :global {
    .ant-switch {
      height: 24px;

      &[aria-checked='false'] {
        background: #0000000d;

        &:focus {
          box-shadow: none;
        }
      }

      .ant-switch-handle {
        height: 20px;
        width: 20px;
      }
    }

    .ant-switch-checked {
      background: $green-AA;

      &:focus {
        box-shadow: none;
      }

      .ant-switch-handle {
        left: calc(100% - 20px - 2px);
      }
    }

    .ant-click-animating-node {
      display: none;
    }

    .switch-warning .ant-switch-checked, .switch-warning.ant-switch-checked{
      background-color: #FC9536 !important;
    }
  }

  display: flex;
  align-items: center;
}

.text {
  min-width: 120px;
  font-size: 14px;

  &Checked {
    color: $text-bw2;
  }

  &Disabled {
    color: $text-bw3;
  }
}
