@import 'styles/variables';

.companySummaryInformation {
  display: flex;
  align-items: center;

  .companyFullName {
    margin-bottom: 5px;
    color: #4b4b4b;
    font-family: $font-semibold;
    font-size: 16px;
  }

  .companyInformation {
    white-space: pre-wrap !important;
    word-break: break-all;
    width: fit-content;
    margin-left: 12px;
  }

  .companyDomain {
    font-size: 14px;
    font-weight: 400;
    color: #6f7282;
  }
}

.note {
  width: 100%;
  background-color: #f6fbff;
  border: 1px solid #f5f5fa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 24px;
  font-size: 14px;
  font-family: $font-regular;
  color: #4b4b4b;

  svg {
    margin-right: 10px;
  }

  p {
    white-space: pre-wrap !important;
    word-break: break-all;
    width: fit-content;
    margin-bottom: 0px;
  }
}

.companyUserPaginationContainer {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;

    span {
      color: #3698fc;
    }
  }
}

:global {
  //.tableCustom {
  //}
  .ant-select-selection-placeholder {
    color: #6f7282 !important;
    font-family: "Poppins Regular" !important;
    font-size: 16px !important;
    //opacity: 0.7 !important;
    //font-weight: bold !important;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell .noticeTitle {
    color: #3698fc;
    cursor: pointer;
  }

  .search-select{
    width: 365px !important;
  }

  .actionMenu {
    margin-right: 6px;
    color: #3698fc;
    //text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    width: 172px;
    border: 1px solid #DDDDE3;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2) !important;
    border-radius: 6px !important;

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
      }
    }

  }

}

.companyUserModal {
  :global {
    .ant-modal-content {
      border-radius: 8px;
    }

    .ant-modal-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .ant-modal-title {
        color: #4b4b4b;
        font-family: $font-semibold;
        font-size: 16px;
      }
    }

    .ant-table-column-title {
      .whitespace-pre-wrap {
        word-break: normal;
      }
    }

    .linkText,
    .changePlan {
      margin-left: 6px;
      color: #3698fc;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }


  }
}

.renderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    margin-right: 5px;
  }
}

.statusContainer {
  border-radius: 12px;
  padding: 0px 12px 0px 12px;
  height: 25px;

  p {
    text-align: center;
    margin-bottom: 0px;
  }
}

.statusColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}