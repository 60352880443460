.userDepartment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f6fbff;
  border: 1px solid #94cdff;
  border-radius: 9px;
  :global {
    .ant-radio-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      width: 100%;

      &::after {
        display: none;
      }
    }
  }

  .userDepartmentTitle {
    display: flex;
    align-items: center;

    .icons {
      padding: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background: rgba(54, 152, 252, 0.08);
      border-radius: 50%;
    }

    .title {
      margin-left: 12px;
      font-weight: 500;
      font-size: 16px;
      color: #4b4b4b;
    }
  }
}
