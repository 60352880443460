.leavePopup {
  :global {
    max-width: 352px !important;
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      .ant-modal-close {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .ant-modal-close-x {
          width: 56px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .ant-modal-body {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 28px;
      }
    }
  }
  .leavePopupContent {
    h2 {
      font-family: 'Poppins Medium';
      color: #4b4b4b;
      font-size: 14px;
    }
    p {
      font-family: 'Poppins Regular';
      font-size: 12px;
      color: #6f7282;
    }
    margin-top: 20px;
    margin-bottom: 36px;
    line-height: 150%;
    color: #4b4b4b;
    font-size: 14px;
    text-align: center;
  }
  .leavePopupBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    a {
      display: block;
    }
    :global {
      .ant-btn-default {
        background-color: #f0f8ff;
        color: #3698fc;
        border-color: #f0f8ff;
      }
      .ant-btn-primary {
        background: #3698fc;
        color: #fff;
      }
    }
    button {
      width: 138px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Poppins Regular';
      font-size: 16px;
      line-height: 150%;
      border-radius: 6px;
    }
  }
}
