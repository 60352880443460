@import '../../../styles//variables';

.formChangePassword {
  img {
    margin-bottom: 80px;
  }
  .changePasswordTitle {
    margin-bottom: 20px;
    color: #4b4b4b;
    font-size: $font-size-huge;
    font-weight: 600;
  }
  .note {
    margin-bottom: 54px;
    color: #4b4b4b;
    font-weight: 400;
    font-family: $font-regular;
    p {
      margin-bottom: 0px;
    }
  }

  .passwordRequired {
    display: flex;
    margin: 8px 0 20px 0;
    padding: 8px 13px;
    background-color: #fbfbff;
    border-radius: 4px;
    font-family: $font-regular;
    font-size: $font-size;
    border: 1px solid #f5f5fa;
    color: #4b4b4b;
    .info {
      margin-left: 8px;
    }
    .info {
      margin-left: 8px;
    }
    p {
      margin-bottom: 0;
      line-height: 22px;
    }
    ul {
      margin: 0 0 0 26px;
      list-style: disc;
    }
  }

  .updatePasswordButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
    p {
      font-family: $font-medium;
      color: #3698fc;
      font-size: 16px;
      cursor: pointer;
    }
    button {
      width: 180px;
      height: 48px;
    }
  }
}
