@import '../../../../../styles//variables';

.editDateModal {
  width: 512px !important;

  :global {
    .ant-form-item.form-input-wrapper {
      margin-bottom: 0.8em;
    }
  }

  .alignButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 26px 0 20px 0;

    button {
      width: 100px;
      height: 40px;
    }

    .editItemButton {
      margin-bottom: 0px;

      p {
        font-family: $font-medium;
        color: #3698fc;
        font-size: 16px;
        cursor: pointer;
      }

      button {
        margin-left: 20px;
      }
    }
  }
}