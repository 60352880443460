// 500
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../assets/poppins-fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins Italic';
  src: local('Poppins Italic'), url('../assets/poppins-fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

//600
@font-face {
  font-family: 'Poppins Semi Bold';
  src: local('Poppins Semi Bold'), url('../assets/poppins-fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins Semi Bold Italic';
  src: local('Poppins Semi Bold Italic'), url('../assets/poppins-fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

//700
@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), url('../assets/poppins-fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins Bold Italic';
  src: local('Poppins Bold Italic'), url('../assets/poppins-fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Prata';
  src: local('Prata'), url('../assets/prata-fonts/Prata-Regular-400.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


