@import 'styles/variables';

.containerInternal {
  position: relative;
  .titleInternal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    :global {
      .ant-btn {
        height: 40px;
      }
    }
    .buttonText {
      margin-left: 8px;
      font-family: $font-regular;
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      color: #27272a;
    }
    .addButton {
      position: absolute;
      right: 0;
      top: -37px;
      .iconsPlus {
        color: #fff;
      }
    }
  }

  .tableInternal {
    background-color: #fff;
    padding: 28px;
    box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    .tabs {
      padding: 10px 16px;
      background-color: #f0f8ff;
      border-radius: 6px;
    }

    .filtersInternal {
      .filtersBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;
        .ant-form-item.form-input-wrapper {
          margin-bottom: 0px !important;
        }
        .selectBox {
          display: flex;
          margin-right: 20px;
          height: 40px;
          .select {
            width: 280px;
            margin-right: 20px;
          }
          .input {
            width: 365px;
            height: 40px;
            :global {
              .ant-form-item.form-input-wrapper input {
                height: unset !important;
              }
              .ant-input-affix-wrapper {
                height: 40px;
                border-radius: 6px !important;
              }
              .ant-input {
                height: unset;
              }
              .ant-input-prefix {
                margin-right: 10px;
              }
            }
          }
        }

        .clearFilters {
          display: flex;
          align-items: center;
          line-height: 24px;
          cursor: pointer;
          span {
            color: #3698fc;
            font-size: 14px;
            font-weight: 500;
            margin-right: 6px;
          }
        }
      }
    }

    .pagination {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .totalResults {
        display: flex;
        .totalNumber {
          color: #3698fc;
          padding-left: 5px;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
  .spin {
    height: 53vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.active {
  text-align: center;
  text-transform: capitalize;
  width: 80px;
  color: #00ab56;
  border-radius: 12px;
  padding: 3px 12px;
  background-color: rgba(0, 171, 86, 0.08);
}

.disable {
  text-align: center;
  width: 80px;
  color: #6f7282;
  border-radius: 12px;
  padding: 3px 12px;
  background-color: rgba(245, 245, 250, 0.9);
  text-transform: capitalize;
}
.renderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userStatus {
  :global {
    .ant-btn {
      &:focus,
      &:active,
      &:hover {
        background-color: unset;
      }
    }
  }
  
  /*
  :hover{
    color:#8fc7ff;
  }
  */
  
  span {
    font-size: 14px;
    color: #3698fc;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
