.tabs {
  :global {
    .ant-tabs {
      overflow: inherit;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        padding-bottom: 16px;
        transform: none !important;
        user-select: none;
        overflow: auto;
      }
    }
    .ant-tabs-tab-active {
      border: none !important;
      .ant-tabs-tab-btn {
        color: #4b4b4b !important;
        border: none;
        background: #f0f8ff;
        text-shadow: none;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .ant-tabs-tab {
      border: none;
      padding: 0;
    }
    .ant-tabs-tab-btn {
      font-weight: 400;
      padding: 8px 16px;
      color: #4b4b4b;
      border: none;
      border-radius: 6px;
      height: 40px;
    }
    .ant-tabs-nav .ant-tabs-ink-bar {
      height: 0px !important;
    }
  }
}
