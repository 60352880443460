@import 'styles/variables';

.dropdownDateInputWrapper {
  :global {
    .ant-picker-date-panel {
      width: 320px;
      //height: 400px;
    }
    .ant-picker-header {
      border-bottom: unset;
      padding-top: 10px;
    }
    .ant-picker-footer {
      display: none;
    }
    .ant-picker-cell .ant-picker-cell-inner {
      font-size: 16px;
      font-weight: 400;
    }
    .ant-picker-body table > thead > tr > th {
      color: #808089;
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-radius: 8px;
    }
    .ant-picker-cell .ant-picker-cell-inner {
      height: 40px;
      width: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-picker-date-panel .ant-picker-content {
      width: 100%;
    }
    .ant-picker-cell-disabled {
      cursor: not-allowed;
      pointer-events: all !important;
    }
    .ant-picker-cell::before {
      height: 56px;
    }
  }
}

.dateInputWrapper {
  :global {
    height: 40px !important;
    border-radius: $border-radius-extra-micro !important;
    border: 1px solid $blue-gray-30 !important;
    width: 100% !important;
    box-shadow: none;

    .ant-picker-input {
      input {
        color: $text-bw3;
        font-family: $font-regular;
        font-size: $font-size-base;
      }
    }
  }
}
.formItem {
  :global {
    .ant-picker-status-error.ant-picker:not([disabled]):hover,
    .ant-picker-status-error.ant-picker {
      background-color: #fff;
      border-color: #ff424e !important;
    }
    [role='alert'] {
      float: unset !important;
      font-style: unset !important;
      font-size: 12px;
      font-weight: 400;
      color: #ff424e;
      font-family: 'Poppins Regular';
    }
  }
}
