@import '../../../styles//variables';

.formForgotPassword {
  :global {
    .ant-form-item-explain-error {
      font-size: 14px !important;
    }
  }
  img {
    margin-bottom: 80px;
  }
  .back {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #4b4b4b;
    margin-bottom: 28px;
    &:hover {
      svg {
        stroke: #3698fc;
        fill: #3698fc;
        transition: 0.2s;
      }
      span {
        color: #3698fc;
        transition: 0.2s;
      }
    }
    span {
      margin-left: 8px;
      font-family: $font-semibold;
    }
  }
  .title {
    margin-bottom: 20px;
    color: #4b4b4b;
    font-size: $font-size-huge;
    font-weight: 600;
  }
  .note {
    margin-bottom: 54px;
    color: #4b4b4b;
    font-weight: 400;
    font-family: $font-regular;
    p {
      margin-bottom: 0px;
    }
  }
  .ant-input {
    height: 44px;
  }
  .ant-form-item.form-input-wrapper.inputEmail {
    .ant-form-item-explain-error {
      font-size: 14px;
    }
  }
  .inputEmail {
    input {
      height: 44px;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
      }
    }
  }
  .resetPasswordButton {
    margin-top: 8px;
    button {
      height: 48px;
    }
  }
  .emailCheck {
    .note {
      margin-bottom: 28px;
      p {
        margin-bottom: 20px;
        &:last-child {
          text-align: center;
        }
      }
    }
    .emailCheckButton {
      display: flex;
      justify-content: center;
      button {
        width: 160px;
        height: 48px;
        span {
          font-family: $font-medium;
          font-size: 16px;
        }
      }
    }
  }
}
