.nodatInternal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin: 60px 0 100px 0;
  height: 53vh;
  .nodataTitle {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 20px;
    color: #4b4b4b;
  }
  .nodataText {
    font-weight: 400;
    font-size: 16px;
    color: #6f7282;
  }
}
