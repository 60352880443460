@import '../../../styles/variables';

.ant-modal.confirm-modal-wrapper {
  padding-bottom: 0px;
  .ant-modal-content {
    padding: 36px 16px 16px 16px;
    background: $white;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 16%);
    border-radius: $border-radius-large;
    button.ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 0px !important;
      text-align: center !important;
      margin-bottom: 36px;
    }
    .ant-modal-footer {
      border-top: unset !important;
      padding: 0px !important;
      display: flex;
      align-items: center;
      justify-content: space-around !important;
      position: relative;
      button {
        border: none;
        box-shadow: unset;
        background-color: transparent !important;
        font-weight: 600 !important;
        height: unset;
        padding: 0px;
      }
      button:first-child {
        color: $blue-gray-400;
      }
      button:last-child {
        color: $red;
      }
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        content: '';
        width: 1px;
        height: 16px;
        background-color: $blue-gray-400;
      }
    }
  }
}
