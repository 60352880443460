@import '../../../styles//variables';

.formChangePasswordModal {
  width: 516px !important;
  :global {
    .ant-form-item.form-password-input-wrapper .ant-input-suffix {
      margin-right: 10px !important;
    }
  }
  .passwordRequired {
    display: flex;
    margin: 8px 0 20px 0;
    padding: 8px 13px;
    background-color: #fbfbff;
    border-radius: 4px;
    font-family: $font-regular;
    font-size: $font-size;
    border: 1px solid #f5f5fa;
    color: #4b4b4b;
    .info {
      margin-left: 8px;
    }
    .info {
      margin-left: 8px;
    }
    p {
      margin-bottom: 0;
      line-height: 22px;
    }
    ul {
      margin: 0 0 0 26px;
      list-style: disc;
    }
  }
  .alignButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 26px 0 20px 0;
    button {
      width: 100px;
      height: 40px;
    }
    .updatePasswordButton {
      margin-bottom: 0px;
      p {
        font-family: $font-medium;
        color: #3698fc;
        font-size: 16px;
        cursor: pointer;
      }
      button {
        margin-left: 20px;
      }
    }
  }
}
