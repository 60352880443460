@import '../../../styles/variables';
.user-info {
  padding: 0 24px;
  position: absolute;
  bottom: 24px;
  width: 100%;
  button {
    height: 68px;
    width: 100%;
    border-radius: $border-radius-large;
  }
}

.my-profile-container {
  width: 328px;
  padding: 16px 16px 12px !important;
  border-radius: 4px !important;
  .ant-dropdown-menu-item {
    font-size: 14px;
    line-height: 150%;
    font-family: 'Poppins Medium';
    padding: 8px 0px 7px;
    color: #4b4b4b;
    &:hover {
      background: $white;
    }
  }
  .ant-divider {
    margin: 12px 0;
    border-top: 1px solid #dddde3;
  }
  .top-info {
    display: flex;
    .avatar {
      position: relative;
      margin-right: 16px;
      .role {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: #2162af;
        color: #ffffff;
        font-family: 'Poppins Medium';
        font-size: 10px;
        line-height: 150%;
        padding: 3px 2px;
        //width: 38px;
        height: 18px;
        border-radius: 2px;
        text-align: center;
        text-transform: capitalize;
        display: flex;
        align-items: center;
      }
    }
    .info {
      .name {
        font-family: 'Poppins SemiBold';
        font-size: 16px;
        line-height: 150%;
        color: #27272a;
      }
      .email {
        font-family: 'Poppins Regular';
        font-size: 12px;
        line-height: 150%;
        color: #6f7282;
        margin-top: 8px;
      }
    }
  }
  .profile-item-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.log-out-modal {
  .ant-modal-content {
    border-radius: 4px;
    background: #ffffff;
    .ant-modal-body {
      padding: 28px;
      width: 352px;
      height: 193px;
      border-radius: 4px;
      h2 {
        font-family: 'Poppins SemiBold';
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #4b4b4b;
        margin-bottom: 16px;
      }
      p {
        font-family: 'Poppins Regular';
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #6f7282;
        margin-bottom: 36px;
      }
      .group-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          width: 138px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Poppins Regular';
          font-size: 16px;
          line-height: 150%;
          border-radius: 6px;
          &.ant-btn-default {
            background-color: #f0f8ff;
            color: #3698fc;
            border-color: #f0f8ff;
          }
          &.ant-btn-primary {
            background: #3698fc;
            color: #fff;
          }
        }
      }
    }
  }
}

[role='alert'] {
  float: right;
  font-style: italic;
}

.change-password-modal {
  .ant-form-item-extra {
    margin: 24px 0;
  }
}

.bottom-input {
  margin-bottom: 16px;
}

.ant-row.ant-form-item.ant-form-item-with-help.form-input-wrapper.ant-form-item-has-feedback.ant-form-item-has-error {
  .ant-form-item-children-icon {
    display: none;
  }
}

.change-pass-form {
  .ant-modal-footer {
    .ant-btn.ant-btn-primary {
      background: $background-gradient;
      color: $white;
      &:hover {
        box-shadow: 0 4px 16px rgba(229, 108, 118, 0.5);
      }
      &:focus,
      &:active {
        background: $base-background;
      }
      &:active {
        box-shadow: none;
      }
      &[disabled],
      &[disabled]:hover {
        color: $white;
        background: $background-gradient;
        opacity: 0.5;
      }
    }
  }
  .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon {
    top: 40%;
  }
}
