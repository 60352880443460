@import 'styles/variables';

.commonSelect {
  :global {
    .ant-select-selector {
      min-height: 40px;
      border-radius: 6px !important;
    }

    .ant-checkbox-wrapper {
      .ant-checkbox {
        display: none;
      }

      span {
        padding: 0;
      }
    }
    .ant-select-arrow {
      .anticon {
        color: #6f7282;
        svg {
          transform: scaleX(1.2);
        }
      }
    }
  }
}

.selectItem {
  width: 100%;
  :global {
    .ant-checkbox + span {
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.tagPreview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  gap: 6px;
  height: 26px;
  background: #f6fbff;
  border: 1px solid #f5f5fa;
  border-radius: 4px;
  margin-right: 6px;
}

.placeHolderSelect {
  :global {
    .ant-select-selection-placeholder {
      font-size: 14px;
      font-family: $font-regular;
      color: #6f7282;
      opacity: 0.6;
    }
  }
}

.placeHolderAll {
  :global {
    .ant-select-selection-placeholder {
      font-size: 14px;
      font-family: $font-regular;
      color: #4b4b4b;
    }
  }
}
