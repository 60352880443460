@import '../../../styles/variables';

.activeModal {
  font-size: $font-size-normal;
  :global {
    .ant-modal-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .ant-modal-title {
        font-family: $font-semibold;
        font-size: 16px;
      }
    }
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-body {
      border-top: none;
      padding: 20px 28px 0px 28px;
    }
    .ant-modal-footer {
      border-top: none;
      padding: 36px 28px 28px 28px;
      .ant-btn {
        border: 1px solid;
        border-radius: 6px;
        font-family: $font-regular;
        max-height: 40px;
        padding-top: 0;
        padding-bottom: 0;
      }
      .ant-btn.ant-btn-default {
        color: #3698fc;
        border-color: #3698fc;
      }
      .ant-btn.ant-btn-primary {
        border-color: #3698fc;
      }
    }
  }
  .iconContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  .content {
    text-align: center;
    color: $text-bw2;
    font-family: $font-regular;
    margin-bottom: 0;
  }
}
