@import './variables';
@import './fonts';
@layer base {
  @font-face {
    font-family: 'Poppins Regular';
    src: local('Poppins Regular'), url(../assets/poppins-fonts/Poppins-Regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Poppins SemiBold';
    src: local('Poppins SemiBold'), url(../assets/poppins-fonts/Poppins-SemiBold.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Poppins Medium';
    src: local('Poppins Medium'), url(../assets/poppins-fonts/Poppins-Medium.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Poppins Bold';
    src: local('Poppins Bold'), url(../assets/poppins-fonts/Poppins-Bold.ttf) format('truetype');
  }
}

#root {
  height: calc(100 * var(--vh));
}

iframe {
  z-index: -1 !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  font-family: $font-family;
  font-size: $font-size-base;
  color: $blue-gray-700;
  background-color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

ul,
ol {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
}

// css for scroll bar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: transparent;
  background: $white;
}

::-webkit-scrollbar-thumb {
  background: #d5d5d5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #464e5f;
}

::-webkit-scrollbar-track-piece {
  border-radius: 0.375rem;
  border: 1px solid #dddde3;
}

.ant-input:hover {
  border-color: #d9d9d9 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #d9d9d9 !important;
}

.ant-input:focus {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #3698fc !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3698fc !important;
  border: none !important;
}

iframe {
  display: none;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
  word-break: break-all;
}
