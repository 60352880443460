.header {
  position: fixed;
  width: 100%;
  height: 80px !important;
  display: flex;
  justify-content: space-between;
  background-color: #0c124b !important;
  .notification {
    margin: 0 34px;
    display: flex;
  }
  .actions {
    display: flex;
    align-items: center;
    position: relative;
  }
  .information {
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    .name {
      margin-right: 12px;
    }
  }
}
