@import 'styles/variables';

.breadcrumb {
  :global {
    ol li:first-child {
      span {
        display: none;
      }
    }
    .ant-breadcrumb {
      font-size: 15px;
    }
    .ant-breadcrumb a {
      color: $blue-60;
      &:hover {
        color: #1389ff;
      }
    }
    .ant-breadcrumb li:last-child a {
      color: $text-bw2;
    }
  }
}
.title {
  font-family: $font-semibold !important;
  font-size: $font-size-body !important;
  color: $text-bw1 !important;
  pointer-events: none;
}
.backPage {
  display: flex;
  align-items: center;
  margin: 9px 0 29px 0;
  font-size: $font-size-large;
  font-family: $font-semibold;
  color: $text-bw1;
  cursor: pointer;
  svg {
    margin-right: 15px;
  }
}
