@import 'styles/variables';

@mixin draggerStyleBase {
  border: 1px dashed $blue-60 !important;
  border-radius: $border-radius-medium !important;
}

.draggerUpload {
  @include draggerStyleBase;
  background: $white !important;
}

.draggerUploadDisabled {
  @include draggerStyleBase;
  background: #0000000d !important;
  pointer-events: none;
  user-select: none;
  border: 1px dashed #0000000d !important;
}

.itemFile {
  border: 1px solid #f5f5fa;
  background: #f6fbff !important;
  border-radius: 8px;
  transition: background 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;

  &:hover {
    background: #f5f5f5 !important;
  }
}

.itemFileError {
  border: 1px solid #ff424f;
  background: #ff424f14 !important;
  transition: opacity 0.3s;
  opacity: 1;

  &:hover {
    background: #ff424f14 !important;
    opacity: 0.8;
  }
}

.buttonImport,
.buttonSave {
  background: $blue-60 !important;
  color: $white !important;
  height: 40px !important;
  border-radius: $border-radius-micro !important;
  padding: 8px 23px !important;
  font-size: $font-size-normal !important;
  font-family: $font-regular !important;

  &:hover {
    background: $blue-60 !important;
    color: $white !important;
    opacity: 0.7 !important;
  }

  &:focus {
    background: $blue-60 !important;
    color: $white !important;
  }

  &:disabled {
    background: #0000000d !important;
    color: #00000026 !important;
  }
}

.buttonCancel {
  background: $blue-10 !important;
  color: $blue-60 !important;
  border-radius: $border-radius-micro !important;
  height: 40px !important;
  padding: 8px 23px !important;
  font-size: $font-size-normal !important;
  font-family: $font-regular !important;
  border: none;

  &:hover {
    background: $blue-10 !important;
    color: $blue-60 !important;
    opacity: 0.7 !important;
  }

  &:focus {
    background: $blue-10 !important;
    color: $blue-60 !important;
  }
}

.buttonDelete {
  background: $blue-60 !important;
  color: $white !important;
  border-radius: $border-radius-micro !important;
  height: 40px !important;
  padding: 8px 23px !important;
  font-size: $font-size-normal !important;
  font-family: $font-regular !important;
  border: none;

  &:hover {
    background: $red-60;
    opacity: 0.8;
    color: $white;
  }

  &:focus {
    background: $red-60;
    color: $white;
  }
}

.deletePopup {
  max-width: 352px !important;

  :global {
    .ant-modal-footer {
      display: flex;
    }

    .mt-5 {
      margin-top: 20px;
    }
  }
}

.wrapperContentPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapperFileIcon {
  position: relative;
}

.fileIcon {
  position: absolute;
  top: -15px;
  left: 12px;
  margin-right: 16px;
  z-index: 10;
}

.ml3 {
  margin-left: 12px !important;
}

.ml5 {
  margin-left: 20px !important;
}

.minWFull {
  min-width: 100%;
}

.noticeInfo {
  border-radius: $border-radius-tiny;
  padding: 8px 12px;
  background: $blue-3;
  margin: 12px 0 28px 0;
}

.noticeText {
  color: $text-bw2;
  margin: 0 8px;
  font-size: $font-size-base;
  font-family: $font-regular;
  max-width: 522px;
}

.dropdownDownloadTemplate {
  position: absolute;
  left: -86px;
  top: -20px;
  border-radius: $border-radius-micro;
  background: $white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 12px;
}

.fileCSV {
  margin-bottom: 16px;

  &:hover {
    background: #f5f5fae5;
  }

  div {
    color: $text-bw2;
    padding: 0 12px;
    min-width: 172px;
    font-family: $font-medium;
  }
}

.fileXLSX {
  &:hover {
    background: #f5f5fae5;
  }

  div {
    color: $text-bw2;
    padding: 0 12px;
    min-width: 172px;
    font-family: $font-medium;
  }
}

.flex {
  display: flex;
  flex-direction: row;
}

.textCenter {
  text-align: center;
}

.wFull {
  width: 100%;
}

.flexBetweenCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pointer {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.textDownload {
  margin-right: 4px;
  color: $blue-60;
  font-family: $font-regular;
  font-size: $font-size-base;
}

.textBW2 {
  color: $text-bw2;
  font-family: $font-medium;
  font-size: $font-size-base;
}

.textBW3 {
  color: $text-bw3;
  font-family: $font-regular;
  font-size: $font-size-tiny;
}

.textBW4 {
  color: $text-bw4;
  font-size: $font-size-tiny;
  font-family: $font-regular;
}

.fontMedium {
  font-family: $font-medium;
}

.fontRegular {
  font-family: $font-regular;
}

.mt9 {
  margin-top: 26px;
}

.mt2 {
  margin-top: 8px;
}

.mb7 {
  margin-bottom: 18px;
}

.mr7 {
  margin-right: 12px;
}

.pb1 {
  padding-bottom: 4px;
}

.w50 {
  width: 50%;
}

.text00000026 {
  color: #00000026;
  font-family: $font-medium;
  font-size: $font-size-base;
}

.text00000026Opacity {
  color: #00000026;
  opacity: 0.7;
}

.text3698FC {
  color: $blue-60;
}

.importBatchSuccessNoti,
.importBatchFailNoti {
  background-color: #00ab56 !important;
  color: #fff !important;
  font-family: $font-regular !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  width: 384px !important;
  height: 87px !important;
  padding: 8px !important;

  :global {
    .ant-notification-notice-with-icon {
      font-style: normal;
      width: 332px;
      float: left !important;

      .ant-notification-notice-message {
        font-family: $font-semibold;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
        margin-left: 40px;
      }

      .ant-notification-notice-description {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 150%;
        margin-left: 40px;
      }
    }

    .ant-notification-notice-close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 16px);
      top: 8px;
      right: 0;
      width: 36px;
      border-left: 1px solid rgba($color: #ffffff, $alpha: 0.5);
    }
  }
}

.importBatchFailNoti {
  width: 576px !important;
  height: auto !important;
  background-color: $red-60 !important;

  :global {
    .ant-notification-notice-with-icon {
      width: 524px;
    }
  }
}