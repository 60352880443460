@import 'styles/variables';

.custom-import {
  .ant-upload-drag-icon {
    display: flex;
    justify-content: center;
  }
  .text-00000026 {
    color: #00000026;
  }
  .opacity-70 {
    opacity: 0.7;
  }
  .text-3698FC {
    color: #3698fc;
  }
  .text-FF424F {
    color: #ff424f;
  }
  .bg-FF424F14 {
    background: #ff424f14;
  }
  .font-medium {
    font-family: $font-medium;
  }
  .text-14 {
    font-size: $font-size-base;
  }
  .rounded {
    border-radius: $border-radius-tiny;
  }
  .py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .bg-FF424F14 {
    background: #ff424f14;
  }
  .mt-3 {
    margin-top: 12px;
  }
  .ml-3 {
    margin-left: 12px;
  }
  .ml-2 {
    margin-left: 8px;
  }
  .ml-8 {
    margin-left: 32px;
  }
  .flex {
    display: flex;
  }
  .flex-row {
    flex-direction: row;
  }
  .items-center {
    align-items: center;
  }
  .items-start {
    align-items: flex-start;
  }
  .justify-center {
    justify-content: center;
  }
  .font-regular {
    font-family: $font-regular;
  }
  .leading-150 {
    line-height: 150%;
  }
  .overflow-hidden {
    overflow: hidden;
  }
}
