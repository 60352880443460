@import 'styles/variables';

.labelRequire {
  display: flex;
}

.text {
  color: $text-bw2;
  margin-right: 4px;
  font-size: $font-size-base;
  font-family: $font-semibold;
}

.require {
  color: $red-60;
}

.warningChar {
  font-size: 9pt;
  font-family: Courier;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  display: inline-block;
  background-color: #FC9536;
  color: white;
  cursor: default;
  text-align: center;
  vertical-align: middle;
}

:global {
  .align-right {
    text-align: right;
    justify-content: flex-end;
  }
}