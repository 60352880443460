@import '../../../styles/variables';
@import '../../../styles/mixin';

.ant-form-item.form-input-wrapper {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  .ant-row {
    flex-direction: column;
  }
  .ant-form-item-explain {
    display: flex;
    flex-direction: column;

    .ant-form-item-explain-error {
      font-size: $font-size-tiny;
      font-family: $font-regular;
      color: $red-60;
      padding-top: 4px;
      float: left;
      font-style: normal;
    }
  }
  margin-bottom: 28px;
  width: 100%;
  .ant-col.ant-form-item-label {
    @include lableInput;
  }
  input {
    height: 40px;
    font-size: $font-size-base;
    color: $text-bw3;
    border-radius: $border-radius-extra-micro;
    font-family: $font-regular;
  }
  [role='alert'] {
    float: left;
    font-style: normal;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: #fff;
    border-color: #ff424e;
  }
}
