@import 'styles/variables';

.mainContent {
  @mixin iconStepBase {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-family: $font-medium;
    font-size: $font-size-base;
    color: $white;
  }

  :global {
    .ant-modal-body {
      .ant-steps.ant-steps-horizontal {
        display: flex;
        justify-content: center;

        .ant-steps-item {
          flex: none;

          .ant-steps-item-container {
            width: fit-content;

            .ant-steps-item-title::after {
              display: none;
            }
          }
        }
      }

      .ant-form-item-explain {
        display: flex;
        flex-direction: column;

        .ant-form-item-explain-error {
          font-size: $font-size-tiny;
          font-family: $font-regular;
          color: $red-60;
          padding-top: 4px;
          float: left;
          font-style: normal;
        }
      }

      .ant-divider.ant-divider-horizontal {
        margin-top: 16px;
        margin-bottom: 24px;
        border-top: 1px solid $blue-gray-10;
      }

      .ant-form-item.form-input-wrapper {
        margin-bottom: 0;
      }

      .previous-step {
        @include iconStepBase;
        background: $blue-50;
      }
      .text-previous-step {
        font-family: $font-medium;
        font-size: $font-size-base;
        color: $blue-50;
      }

      .current-step {
        @include iconStepBase;
        background: $blue-60;
      }
      .text-current-step {
        font-family: $font-medium;
        font-size: $font-size-base;
        color: $blue-60;
      }

      .next-step {
        @include iconStepBase;
        background: $text-bw3;
      }
      .text-next-step {
        font-family: $font-regular;
        font-size: $font-size-base;
        color: $text-bw3;
      }

      .notice-info {
        display: flex;
        background: $blue-3;
        border-radius: $border-radius-extra-micro;
        border: 1px solid $blue-10;
        padding: 8px 12px;

        .notice-text {
          color: $text-bw2;
          font-family: $font-regular;
          font-size: $font-size-base;
          padding-left: 8px;
          max-width: 677px;
        }
      }

      .plan-includes {
        background: $gray-10;
        padding: 16px;
        border: 1px solid $blue-gray-30;
        border-radius: $border-radius-micro;

        .options-wrapper {
          background: $white;
          border: 1px solid $blue-gray-30;
          border-radius: $border-radius-extra-micro;
          padding: 12px 16px;

          .ant-divider-horizontal {
            margin: 8px 0 22px 0;
          }
        }

        .switch-input {
          .text-switch {
            font-family: $font-regular;
          }
        }
      }

      .group-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 36px;
        .var-default-button {
          margin-right: 20px;
        }
        .ant-btn {
          height: 40px;
          width: 100px;
        }
      }

      .form-input-wrapper {
        .ant-input {
          max-height: 40px;
        }
      }

      .mx-5 {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .pb-3 {
        padding-bottom: 12px;
      }

      .pb-1 {
        padding-bottom: 4px;
      }

      .mt-5 {
        margin-top: 20px;
      }

      .mb-5 {
        margin-bottom: 20px !important;
      }
      .mb-2 {
        margin-bottom: 16px !important;
      }
      .mb-0 {
        margin-bottom: 0;
      }

      .flex {
        display: flex;
      }

      .justify-center {
        justify-content: center;
      }

      .items-center {
        align-items: center;
      }

      .min-h-84 {
        min-height: 84px;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-top: 36px !important;
      padding-bottom: 20px !important;

      .ant-btn {
        max-height: 40px;
        min-width: 110px;
      }

      .w-100 {
        width: 100%;
      }

      .ml-auto {
        margin-left: auto;
      }

      .mr-2 {
        margin-right: 8px;
      }

      .ml-2 {
        margin-left: 8px;
      }
    }
  }
}
