@import 'styles/variables';

.checkboxPanel {
  :global {

    position: relative;
    font-size: $font-size;

    .mainCheckbox {
      position: absolute;
      top: 0px;
      right: 41px;

      .ant-form-item-control-input {
        min-height: 0px;
      }

    }

    .region-selector{
      margin-top: 15px;
      margin-bottom: 20px;
    }

    .expandIcon {
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
      opacity: 0.6;
    }

    .panelDivider {
      margin-bottom: 5px !important;
    }

    .ant-tabs-tab-btn {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .tableHeader {
      padding: 0px;
      //gap: 15px;

      height: 21px;
    }

    .tableRow {
      padding: 0px 0px 12px;
      //gap: 9px;
      margin-top: 9px;

      border-bottom: 1px solid #F5F5FA;
    }

  }
}