@import 'styles/variables';

.modalBase {
  :global {
    max-width: 784px !important;
    .ant-modal-content {
      border-radius: $border-radius-tiny;
      background-color: $white;

      button {
        border: none;
        font-size: $font-size-normal;
      }

      .ant-modal-close {
        position: absolute;
        top: -3px;
        right: 10px;
      }

      .ant-modal-header {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        padding: 12px 28px;
        border-bottom: 1px solid #dddde3;

        .ant-modal-title {
          font-family: $font-semibold;
          font-size: $font-size-normal;
          color: $text-bw2;
        }
      }

      .ant-modal-body {
        padding: 20px 28px 0 28px;

        .ant-upload-list-item {
          margin-top: 0;

          .ant-upload-list-item-info {
            padding: 0;
          }

          .ant-upload-text-icon {
            display: none !important;
          }

          .ant-upload-list-item-name {
            padding-left: 0;
            font-size: $font-size;
            font-family: $font-medium;
            color: $text-bw2;
          }
        }

        .file-list-container {
          position: relative;
          padding-left: 60px;
          padding-top: 8px;
          padding-bottom: 8px;
        }

        .ant-upload-list-text-container {
          margin-top: 12px;
        }

        .ant-upload-list-item-card-actions {
          position: absolute;

          .ant-upload-list-item-card-actions-btn,
          .ant-upload-list-item-card-actions-btn:disabled {
            opacity: 1;
            top: 10px;
            right: 16px;
          }
        }
      }

      .ant-modal-footer {
        border: none;
      }
    }

    .custom-import {
      .ant-upload-list-item-progress {
        bottom: -38px;
        left: 0;
        padding-left: 0;
      }
    }
  }
}
