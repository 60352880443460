@import '../../../styles//variables';

.flex {
  display: flex;
  .ant-checkbox + span {
    font-family: $font-regular;
    color: #4b4b4b;
  }
}

.justify-between {
  justify-content: space-between;
}

.ant-form-item.form-input-wrapper.login-input {
  .ant-form-item-explain-error {
    font-size: $font-size;
    font-family: $font-regular;
    color: $red-60;
    padding-top: 4px;
    float: left;
    font-style: normal;
  }
  input {
    height: 44px;
    font-size: $font-size;
    color: $text-bw3;
    border-radius: $border-radius-extra-micro;
    font-family: $font-regular;
  }
}

.ant-form-item.navigate-text {
  a {
    color: #3698fc !important;
    font-size: $font-size-normal;
    font-family: $font-regular;
    font-weight: 400;
  }
  p {
    height: 100%;
  }
}

.form-area {
  img {
    margin-bottom: 80px;
  }
  .welcome {
    margin-bottom: 20px;
    color: #4b4b4b;
    font-size: $font-size-huge;
    font-weight: 600;
  }
  .note {
    margin-bottom: 54px;
    color: #4b4b4b;
    font-weight: 400;
    font-family: $font-regular;
    p {
      font-size: 16px !important;
      margin-bottom: 0px;
    }
  }
  .password {
    margin-bottom: 22px;
  }
  .text-label {
    font-size: 16px;
    margin-bottom: 4px;
  }
  .var-primary-button {
    height: 48px;
  }
  .ant-form-item-control-input-content {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
    }
    .ant-input-suffix {
      margin-right: 12px;
    }
  }
}

.ant-notification-notice.login-noti {
  position: absolute;
  // left: 0;
  // right: 0;
  display: flex;
  justify-content: center;
}
