@import 'styles/variables';

.customPagination {
  font-family: Poppins;
  color: $text-bw2;

  li {
    border-radius: 4px;
    button {
      border-radius: 4px !important;
    }
    a {
      font-size: 14px;
    }
  }
  .ant-pagination-item {
    border-radius: 4px !important;
  }
  .ant-pagination-item:hover {
    border-color: #3698fc;
  }
  .ant-pagination-item:focus,
  .ant-pagination-item-active {
    background-color: #3698fc;
    border-color: #3698fc;
    a {
      color: white;
    }
  }

  .ant-select-selector {
    border-radius: 4px !important;
    .ant-select-selection-item {
      color: $text-bw2;
      font-size: 14px;
      font-family: $font-regular;
    }
  }
  .ant-select-item-option-content {
    font-size: 14px;
  }
  .ant-select-arrow {
    color: #555555;
  }
}
