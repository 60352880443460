.successPopup {
  :global {
    max-width: 330px !important;
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      .ant-modal-header {
        padding: 15px 28px 12px 28px;
        border-bottom: 1px solid #dddde3;
        border-radius: 8px 8px 0px 0px;
      }
      .ant-modal-close {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .ant-modal-close-x {
          width: 56px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .ant-modal-body {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 28px 28px;
      }
    }
  }
  .successPopupContent {
    margin-top: 20px;
    margin-bottom: 36px;
    font-family: 'Poppins Regular';
    line-height: 150%;
    color: #4b4b4b;
    font-size: 14px;
    text-align: center;
  }
  .successPopupBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins Regular';
    line-height: 150%;
    font-size: 16px;
    padding: 8px 12px;
    width: 100px;
    height: 40px;
    background: #3698fc;
    border-radius: 6px;
  }
}
