@import 'styles/variables';

.changePlanModal {
  :global {
    .change-plan {
      .company {
        display: flex;
        align-items: center;
        .company-info {
          margin-left: 12px;
          white-space: pre-wrap !important;
          word-break: break-all;
          width: fit-content;
          .name {
            font-weight: 500;
            color: #4b4b4b;
          }
          .link {
            color: #6f7282;
            font-size: 14px;
          }
        }
      }
    }
    .plan-includes {
      background: #fbfbff;
      padding: 16px;
      border: 1px solid #dddde3;
      border-radius: 6px;
      .blueOnion-hub,
      .kiosk-hub,
      .total-control,
      .blueOnion-premium-modules,
      .blueOnion-modules-group {
        background: #ffffff;
        border: 1px solid #dddde3;
        border-radius: 4px;
        padding: 12px 16px;
        .ant-form-item {
          margin-bottom: 0px;
        }
        .ant-divider-horizontal {
          margin: 8px 0 22px 0;
        }
      }
      .ant-form-item.form-input-wrapper {
        margin-bottom: 0;
      }
    }
    .linkText, .linkTextUnselected{
      color: #3698fc;
      cursor: pointer;
      font-size: 14px;
    }
    .linkTextUnselected{
      opacity: 0.6;
    }
    .linkText:hover, .linkTextUnselected:hover{
      opacity: 0.8;
    }
    .group-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 36px;
      .var-default-button {
        margin-right: 20px;
      }
      .ant-btn {
        height: 40px;
        width: 100px;
      }
    }
    .step-button{
      margin-top: 36px;
      .ant-btn {
        height: 40px;
        width: 100px;
      }
    }
    .tableHeaders {
      padding: 0px;
      height: 21px;
      font-size: 14px;
    }
    .tableRow {
      padding: 0px 0px 12px;
      margin-top: 9px;
      border-bottom: 1px solid #F5F5FA;
    }

    .role-row-date-icon:hover{
      opacity: 0.8 !important;
    }
  }
}
.errSwitch {
  font-size: 12px;
  color: #ff424f;
  font-family: $font-regular;
  margin-left: 1px;
}
.errSwitchNone {
  height: 16px;
}

.checkboxPanel {
  :global {

    position: relative;
    font-size: $font-size;

    .mainCheckbox {
      position: absolute;
      top: 0px;
      right: 41px;

      .ant-form-item-control-input {
        min-height: 0px;
      }

    }

    .expandIcon {
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
      opacity: 0.6;
    }

    .panelDivider {
      margin-bottom: 5px !important;
    }

  }
}