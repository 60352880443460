@import 'styles/variables';

.tableCustom {
  span[class='ant-table-column-sorter-inner'] {
    display: none;
  }
  .ant-table {
    border-radius: 6px;
    .ant-table-container {
      border: 1px solid #dddde3;
      border-radius: 6px;
      table > thead {
        th {
          color: #4b4b4b;
          padding: 6px 16px;
        }
        th:first-child {
          border-top-left-radius: 6px;
        }
        th:last-child {
          border-top-right-radius: 6px;
        }
      }
    }
    thead > tr > .ant-table-cell {
      color: #4b4b4b;
      font-size: 14px;
      font-family: $font-medium;
      border-left: 1px solid #dddde3;
      &:first-child {
        border-left: unset;
      }
    }
    tbody {
      .ant-table-cell {
        color: #4b4b4b;
        font-size: 14px;
        font-family: $font-regular;
        &:last-child {
          border-left: 1px solid #dddde3;
        }
      }
    }
    .ant-table-thead > tr {
      th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }
      th {
        height: 48px;
        padding: 0 0 0 16px;
        text-align: left;
        background: #f3f6f9;
        border-bottom: 1px solid #dddde3;
        .ant-table-cell::before {
          position: unset;
        }
      }
    }
  }
}
