@import 'styles/variables';

:global {

  //.tableCustom {
  //}
  .ant-select-selection-placeholder {
    color: #6f7282 !important;
    font-family: "Poppins Regular" !important;
    font-size: 16px !important;
    //opacity: 0.7 !important;
    //font-weight: bold !important;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell .noticeTitle {
    color: #3698fc;
    cursor: pointer;
  }

  .search-select {
    width: 365px !important;
  }

  .actionMenu {
    margin-right: 6px;
    color: #3698fc;
    //text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    width: 172px;
    border: 1px solid #DDDDE3;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2) !important;
    border-radius: 6px !important;

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
      }
    }

  }

}

.renderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    margin-right: 5px;
  }
}

.rolePermissionModal {
  :global {
    .ant-modal-content {
      border-radius: 8px;
    }

    .renderTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    
      .title {
        margin-right: 5px;
      }
    }
    
    .ant-btn, .ant-btn:hover {
      height: 40px;
      background-color: #f0f8ff;
      border: none;
      border-radius: 6px;
    }

    .ant-modal-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .ant-modal-title {
        color: #4b4b4b;
        font-family: $font-semibold;
        font-size: 16px;
      }
    }

    .ant-table-column-title {
      .whitespace-pre-wrap {
        word-break: normal;
      }
    }

    .linkText,
    .changePlan {
      margin-left: 6px;
      color: #3698fc;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }

    .filter-hub-text {
      color: #4b4b4b;
    }

    .filter-hub-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .ant-form-item.form-input-wrapper {
        margin-bottom: 0;
      }
    }

    .filter-hub-top-text{
      display: none;
    }

    .filter-hub-options {
      display: flex;

      .select {
        width: 280px;
        margin-right: 20px;
      }

      .input {
        width: 365px;
        height: 40px;

        .ant-input-affix-wrapper {
          height: 40px;
          border-radius: 6px;
        }

        .ant-input {
          height: unset;
        }

        .ant-input-prefix {
          margin-right: 10px;
        }
      }
    }

    .clear-filters {
      display: none;
      align-items: center;
      cursor: pointer;

      span {
        color: #3698fc;
        font-size: 14px;
        font-weight: 500;
        margin-right: 6px;
      }
    }

    .pagination {
      margin-top: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .total-results-text {
        display: flex;
        font-weight: 500;
      }

      .total-results-number {
        color: #3698fc;
        margin-left: 8px;
      }
    }

  }
}