@import '../../../styles/variables';
@import '../../../styles/mixin';

.ant-form-item.form-password-input-wrapper {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  .ant-form-item-control-input {
    max-height: 44px;
  }
  .ant-form-item-explain-error {
    font-size: $font-size;
    font-family: $font-regular;
    color: $red-60;
    padding-top: 4px;
    float: left;
    font-style: normal;
  }
  .ant-row {
    flex-direction: column;
    .ant-input-affix-wrapper {
      padding: 0px;
      border-radius: $border-radius-extra-micro;
      input {
        border-radius: $border-radius-extra-micro;
        padding: 0px 11px;
        border: none;
        font-size: $font-size;
        color: #6f7282;
        font-family: $font-regular;
        height: 44px;
      }
    }
  }
  margin-bottom: 10px;
  width: 100%;
  .ant-input-suffix {
    margin-right: 4px;
  }
  svg {
    height: 20px;
    width: 20px;
  }
  .ant-col.ant-form-item-label {
    @include lableInput;
  }
  [role='alert'] {
    float: left;
    font-style: normal;
  }
  .anticon svg {
    color: #4b4b4b;
  }
}
