@import '../../styles/variables';



.noti-container {
    position: absolute;
    // top: -72px;
    top:0;
    left: 0;
    z-index: 100;
    width: 100%;
    background-color: #FF424F;
    color: #FFFFFF;
    font-size: 1px;
    font-weight: 500;
    padding: 14px 50px ;
    display: flex;
    justify-content: space-around;
    div {
        font-size: $font-size-normal;
        width: 95%;
        margin-left: 10px;
    }
    svg {
        width: 20px;
        height: 20px;
    }
}

// @keyframes slideDown  {
//     0%, 100% { top: -72px; }
//     10%, 90%{ top: 0; }
// }
