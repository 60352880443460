@import 'styles/variables';

.warningModal {
  :global {
    max-width: 330px !important;
    .ant-modal-content {
      .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 56px 20px 28px 20px;

        .text-warning {
          font-family: $font-regular;
          font-size: $font-size-base;
          color: $text-bw2;
        }

        .button-submit {
          height: 40px;
          padding: 8px 38.5px !important;
          font-family: $font-regular;
          margin-top: 36px;
        }
      }
    }

    .pt-5 {
      padding-top: 20px;
    }
  }
}
