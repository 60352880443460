@import '../../styles/variables';

@mixin common-row-styling {
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto;
  margin-bottom: 100px;
  column-gap: 80px;
}

.fontPrata {
  font-family: 'Prata';
  font-weight: 400 !important;
}

.background {
  background: url(../../assets/img/login//login-background.png);
  grid-column-start: 1;
  grid-column-end: 2;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminForm {
  width: 100vw;
  height: 100vh;
  background: $login-background-gradient;
  display: grid;
  grid-template-columns: 65% 35%;
  gap: 0px;
  min-width: 1576px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: #f5f5fa;
  height: 80%;
  width: 80%;
  top: 100%;
}

.form {
  position: relative;
  background-color: white;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sign-up {
    display: flex;
    justify-content: center;
  }
}

.iconRow {
  @include common-row-styling;
  .horizontalStick {
    svg {
      align-self: center;
      display: flex;
    }
  }
  .iconContainer {
    display: flex;
    justify-content: space-between;
    width: 70%;
  }
}

.sloganRow {
  @include common-row-styling;
  .horizontalStick {
    svg {
      align-self: center;
      display: flex;
    }
  }
  span {
    font-size: $font-size-extra-huge;
    font-style: $font-bold;
    color: $white;
    font-weight: 600;
  }
}

.noteRow {
  @include common-row-styling;
  margin-bottom: 0px !important;
  .horizontalStick {
    z-index: -1;
  }
  span {
    font-size: $font-size-normal;
    font-family: $font-regular;
    color: $white;
    max-width: 90%;
  }
}
