@import 'styles/variables';


:global {

  .ant-modal-content .ant-modal-body .var-primary-button,
  .ant-form-item .ant-form-item-control .var-primary-button,
  .ant-layout .ant-layout-content .var-primary-button,
  .ant-form .group-btn .var-primary-button,
  .group-btn .var-primary-button,
  .var-primary-button {
    font-family: $font-family;
    font-size: $font-size-normal;
    background: #3698fc;
    border-radius: $border-radius-micro;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
      box-shadow: none;
    }

    &:hover {
      background: #43a1ff;
      transition: 0.3s;
    }

    &[disabled],
    &[disabled]:hover {
      color: $white;
      background: $button-disable;
      opacity: 0.5;
      box-shadow: none;
      color: rgba(0, 0, 0, 0.15);
      font-weight: 500;
    }
  }

  .ant-modal-content .ant-modal-body .var-default-button,
  .ant-form-item .ant-form-item-control .var-default-button,
  .ant-layout .ant-layout-content .var-default-button,
  .ant-form .group-btn .var-default-button,
  .group-btn .var-default-button,
  .var-default-button {
    font-family: $font-family;
    font-size: $font-size-normal;
    border: 1px solid #3698fc;
    border-radius: $border-radius-micro;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3698fc;

    &:hover {
      box-shadow: none;
    }

    &[disabled],
    &[disabled]:hover {
      background: $white;
      opacity: 0.5;
    }
  }

  .ant-modal-content .ant-modal-body .var-dashed-button,
  .ant-form-item .ant-form-item-control .var-dashed-button,
  .ant-layout .ant-layout-content .var-dashed-button,
  .ant-form .group-btn .var-dashed-button,
  .group-btn .var-dashed-button,
  .var-dashed-button {
    font-size: $font-size-normal;
    line-height: $line-height-body;
    border: 1px solid $blue-gray-100;
    border-radius: $border-radius-medium;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: none;
      background: transparent !important;
    }

    &:focus,
    &:active {
      background: transparent !important;
      border: 1px solid $blue-gray-100;
    }

    &[disabled],
    &[disabled]:hover {
      background: unset;
      opacity: 0.5;
    }

  }

  .space-small {
    padding: 8px 12px !important;
  }

  .space-medium {
    padding: 8px 16px !important;
  }

  .space-large {
    padding: 8px 20px !important;
  }

  .reverse-icon {
    flex-direction: row-reverse !important;
  }

  .custom-width {
    width: 121px !important;
  }
}