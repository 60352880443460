@import 'styles/variables';

.external-user {
  position: relative;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    .ant-btn {
      position: absolute;
      height: 40px;
      right: 0;
      top: -37px;
    }
    .button-text {
      margin-left: 8px;
      font-family: $font-regular;
    }
  }
  .content {
    padding: 28px;
    background-color: white;
    box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    .ant-btn {
      height: 40px;
      background-color: #f0f8ff;
      border: none;
      border-radius: 6px;
    }
    .ant-divider {
      margin: 16px 0 18px 0;
      color: #dddde3;
    }
    .filter-hub-text {
      color: #4b4b4b;
    }
    .filter-hub-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 28px;
      .ant-form-item.form-input-wrapper {
        margin-bottom: 0;
      }
    }
    .filter-hub-options {
      display: flex;
      .select {
        width: 280px;
        margin-right: 20px;
      }
      .input {
        width: 365px;
        height: 40px;
        .ant-input-affix-wrapper {
          height: 40px;
          border-radius: 6px;
        }
        .ant-input {
          height: unset;
        }
        .ant-input-prefix {
          margin-right: 10px;
        }
      }
    }
    .clear-filters {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        color: #3698fc;
        font-size: 14px;
        font-weight: 500;
        margin-right: 6px;
      }
    }
    .pagination {
      margin-top: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .total-results-text {
        display: flex;
        font-weight: 500;
      }
      .total-results-number {
        color: #3698fc;
        margin-left: 8px;
      }
    }
    .ant-table-cell {
      .renderTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          padding-right: 8px;
        }
      }
    }
    .noDataInternal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 60px 0 100px 0;
      .noDataTitle {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 20px;
      }

      .noDataText {
        font-weight: 400;
        font-size: 16px;
        color: #6f7282;
      }
    }
    .spin {
      height: 53vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .blueOnionHub {
    display: flex;
    .blueOnionHub-list {
      height: 24px;
      width: 36px;
      margin: 0 6px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
    }
  }
  .numberOfUser {
    display: flex;
    align-items: center;
  }
  .changePlan {
    margin-left: 6px;
    color: #3698fc;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .viewAll {
    margin-left: 6px;
    color: #3698fc;
    cursor: pointer;
  }
  .ant-table-tbody > tr > td {
    padding: 10px 16px;
  }
}

.spin {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
