@import '../../../../styles//variables';

.box {
  :global {
    .group-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 28px;
      padding-bottom: 20px;

      .var-default-button {
        margin-right: 20px;
      }

      .var-primary-button {
        span {
          margin-left: 5px;
        }
      }

      .ant-btn {
        height: 40px;
        width: 128px;
      }
    }

    .ant-form-item-explain {
      display: flex;
      flex-direction: column;

      .ant-form-item-explain-error {
        font-size: $font-size-tiny;
        font-family: $font-regular;
        color: $red-60;
        padding-top: 4px;
        float: left;
        font-style: normal;
      }
    }

    .pb-1 {
      padding-bottom: 4px;
    }

    .mb-0 {
      margin-bottom: 0;
    }
  }
}

.formAddRelationModal {
  width: 516px !important;

  :global {
    .ant-form-item.form-input-wrapper {
      margin-bottom: 0.8em;
    }
  }

  .alignButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 26px 0 20px 0;

    button {
      width: 100px;
      height: 40px;
    }

    .addRelationButton {
      margin-bottom: 0px;

      p {
        font-family: $font-medium;
        color: #3698fc;
        font-size: 16px;
        cursor: pointer;
      }

      button {
        margin-left: 20px;
      }
    }
  }
}