// ================================ Fonts  ==========================================
$font-family: 'Poppins', sans-serif;
$font-family-italic: 'Poppins Italic';
$font-regular: 'Poppins Regular';
$font-medium: 'Poppins Medium';
$font-semibold: 'Poppins SemiBold';
$font-semibold-italic: 'Poppins SemiBold Italic';
$font-bold: 'Poppins Bold';
$font-bold-italic: 'Poppins Bold Italic';

$font-size-tiny: 12px;
$font-size: 14px;
$font-size-lower-normal: 15px;
$font-size-normal: 16px;
$font-size-medium: 18px;
$font-size-large: 20px;
$font-size-body: 24px;
$font-size-huge: 28px;
$font-size-extra-huge: 48px;
$line-height-tiny: 18px;
$line-height-body: 24px;
$font-family-base: $font-family;
$font-size-base: $font-size;

// ================================ Colors ==========================================
$base-background: rgba(94, 97, 112, 0.9);
//dark-blue
$dark-blue: #1b005a;
//blue-gray
$blue-gray-10: #f5f5fa;
$blue-gray-30: #dddde3;
$blue-gray-50: #f5f5f7;
$blue-gray-100: #e7e7ec;
$blue-gray-200: #d9d9e0;
$blue-gray-300: #cbcbd5;
$blue-gray-400: #a9a9ba;
$blue-gray-700: #696984;
$blue-gray-900: #2c2c37;
//red
$red: #f44336;
$red-60: #ff424f;
//green:
$green: #4caf50;
$green-AA: #63d26b;
//yellow
$yellow: #ffc107;
//gradient
$gradient: linear-gradient(183.13deg, #e56c76 2.59%, #d8528e 97.41%);
$background-gradient: linear-gradient(225deg, #e56c76 0%, #d8528e 100%);
$login-background-gradient: linear-gradient(
  90deg,
  #1b9dd9 0%,
  #5ebfec 29.91%,
  #79c3e6 48.56%,
  rgba(255, 186, 199, 0.8) 70.51%,
  rgba(255, 186, 199, 0.26) 100%,
  rgba(255, 237, 240, 0.8) 100%
);
$button-disable: rgba(0, 0, 0, 0.05);
//white
$white: #ffffff;
//black
$black: #000000;
//label
$status-active: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
  linear-gradient(0deg, #4caf50, #4caf50);
$status-inactive: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
  linear-gradient(0deg, #ffc107, #ffc107);
//text
$text-bw1: #27272a;
$text-bw2: #4b4b4b;
$text-bw3: #6f7282;
$text-bw4: #959cb6;
//blue
$blue-10: #f0f8ff;
$blue-40: #94cdff;
$blue-50: #5cb3ff;
$blue-60: #3698fc;
$blue-3: #f6fbff;
//gray
$gray-10: #fbfbff;

// ================================ Border Radius ==========================================
$border-radius-huge: 24px;
$border-radius-large: 16px;
$border-radius-medium: 12px;
$border-radius-small: 10px;
$border-radius-tiny: 8px;
$border-radius-micro: 6px;
$border-radius-extra-micro: 4px;
$border-radius-mini: 2px;

// ================================ Border ==========================================
