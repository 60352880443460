@import 'styles/variables';

.selectSearch {
  :global {
    width: 100%;

    .ant-select-selector {
      min-height: 40px;
      display: flex;
      align-items: center;
      border-radius: $border-radius-extra-micro !important;

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        font-size: $font-size-base;
        font-family: $font-regular;
      }

      .ant-select-selection-placeholder {
        color: #6f7282;
        opacity: 0.6;
      }

      input {
        height: 100% !important;
      }
    }
  }
}

.selectSearchDropdown {
  :global {
    padding: 12px !important;

    .ant-select-item-empty {
      font-size: 14px;
    }

    .ant-form-item.form-input-wrapper {
      margin-bottom: 12px;
    }

    .ant-select-item-option {
      font-size: $font-size-base;
      font-family: $font-regular;
      color: $text-bw2;
      border-radius: $border-radius-mini;
      padding: 8px 16px;
    }

    .ant-select-item-option-active {
      background: $blue-3 !important;
      font-family: $font-semibold;
    }

    .ant-select-item-option-disabled {
      opacity: 0.5;
    }

    .ant-input-affix-wrapper {
      font-size: $font-size-base;
      color: $text-bw3;
      border-radius: $border-radius-extra-micro;
      font-family: $font-regular;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}